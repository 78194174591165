import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

import Link from './Link'
import Carousel from './Carousel';
import Section from './Section';
import ModalVideo from './ModalVideo'

import stars from '../images/home2022/MyHR_Home_Stars.png'
import capterra from '../images/home2022/MyHR_Home_Capterra.png'
import google from '../images/home2022/MyHR_Home_Google.png'

import starsLight from '../images/home2022/MyHR-Home_Stars@2x.png'
import capterraLight from '../images/home2022/MyHR-Home_Capterra@2x.png'
import googleLight from '../images/home2022/MyHR-Home_Google@2x.png'


const THEMES = {
  dark: {
    googleImg: google,
    capterraImg: capterra,
    starsImg: stars,
    buttonVariant: "outline-cyan",
    colorClass: "text-white",
    bgClass: ""
  },
  light: {
    googleImg: googleLight,
    capterraImg: capterraLight,
    starsImg: starsLight, 
    buttonVariant: "outline-navy",
    colorClass: "text-navy",
    bgClass: "bg-light"
  }
}



import './CustomerStorySliderSection.scss';

const CustomerStorySliderSection = (props) => {
  const theme = THEMES[props.theme] || THEMES['dark'];
  const title = props.title;
  
  const stories = props.stories && props.stories.map((story, i) => (
    <Row className="my-5" key={i}>
      <Col
        lg={{ size: 6, offset: 0, order: 12 }}
      >
        {story.video.url &&
          <ModalVideo {...story.video}>
            {({ toggle }) => (
              <div className="UntangleHrSection__video cursor-pointer" onClick={toggle}>
                <img src={story.video.image} alt={'Video Thumbnail'} />
                <div className="UntangleHrSection__video-play-icon">
                  <FontAwesomeIcon
                    icon={faPlay}
                    className="icon play-icon"
                  />
                </div>
              </div>
            )}
          </ModalVideo>
        }
        {!story.video.url &&
          <div className="UntangleHrSection__video">
            <img src={story.video.image} alt={'Video Thumbnail'} />
          </div>
        }
      </Col>
      <Col
        lg={{ size: 6, offset: 0, order: 1 }}
      >
          <div className="CustomerStorySliderSection__Story">
            <h3 className="my-4 pr-0 pr-md-4 CustomerStorySliderSection__StoryQuote">
              “{story.quote}”
            </h3>
            <div className="CustomerStorySliderSection__StoryBy">
              <p>
                <span>{story.name}</span>
              </p>
              <img
                src={story.logo}
                className="CustomerStorySliderSection__StoryLogo"
                alt="Logo"
              />
            </div>
          
            {story.moreStoriesButton &&
              <Link
                type="button"
                color={theme.buttonVariant}
                href="/customer-stories"
                className="customerStoriesBtn"
                localePrefix={true}
              >
                More customer stories
              </Link>
            }

            {!story.moreStoriesButton && story.video.url &&
              <ModalVideo {...story.video}>
                {({ toggle }) => 
                  <Button
                    onClick={toggle}
                    color={theme.buttonVariant}
                  >
                    <FontAwesomeIcon icon={faPlay} className="mr-2 ml-0" />
                    Watch customer story
                  </Button>
                }
              </ModalVideo>
            }
            <p>&nbsp;</p>
          </div>
      </Col>
    </Row>
  ));

  return (
    <Section className={`CustomerStorySliderSection py-5 justify-content-center d-flex flex-column align-items-center isolated-section ${theme.bgClass} ${theme.colorClass}`}>
      {props.title && <h2 className="text-center">{title}</h2>}
      <Container>
        {stories && stories.length > 1 && <Carousel items={stories} />}
        {stories && stories.length == 1 && stories[0]}

        {props.stars &&
          <div className="d-flex align-items-center flex-row justify-content-center">
            <div className="capterra d-flex align-items-center flex-row">
              <img src={theme.capterraImg} className="logo" />
              <img src={theme.starsImg} className="stars" />
            </div>
            <div className="google d-flex align-items-center flex-row">
              <img src={theme.googleImg} className="logo" />
              <img src={theme.starsImg} className="stars" />
            </div>
          </div>
        }
      </Container>
    </Section>
  )
}

export default CustomerStorySliderSection