import customerParkableLogo from '../images/features/customer_story_logos/Parkable.png'
import customerParkableVideoImage from '../images/features/customer_thumbnails/MyHR_Features_Parkable_Thumb.png'
import customerKaarLogo from '../images/features/customer_story_logos/KAAR_1.png'
import customerKaarVideoImage from '../images/features/customer_thumbnails/MyHR_Features_KAAR_thumb.jpg'
import customerCarpetCourtLogo from '../images/features/customer_story_logos/carpetcourt.png'
import customerCarpetCourtVideoImage from '../images/features/customer_thumbnails/CarpetCourt.png'
import customerRoostersLogo from '../images/features/customer_story_logos/roosters.png'
import customerRoostersVideoImage from '../images/features/customer_thumbnails/Roosters.png'
import customerHseLogo from '../images/features/customer_story_logos/HSE2.png'
import customerHseVideoImage from '../images/features/customer_thumbnails/MyHR_Features_HSE_thumb.jpg'
import customerSaveTheKiwiLogo from '../images/features/customer_story_logos/SaveTheKiwi.png'
import customerNzWarriorsVideoImage from '../images/customer-stories/nz-warriors-thumbnail.jpg'
import customerNzWarriorsLogo from '../images/customer-stories/nz-warriors-logo.png'
import customerSilverbackVideoImage from '../images/customer-stories/silverback-thumbnail.jpg'
import customerSilverbackLogo from '../images/customer-stories/silverback-logo.png'

export default {
  parkable: {
    logo: customerParkableLogo,
    name: 'Loes St. Ather, People & Culture Lead',
    quote:
      'I feel supported in decisions, contracting and processes... the MyHR team really feels like an extension of Parkable.',
    video: {
      image: customerParkableVideoImage,
      url: 'https://www.youtube.com/embed/pBXoqzT5uqY',
      channel: 'youtube',
    },
    className: 'parkable',
  },
  kaar: {
    logo: customerKaarLogo,
    name: 'Kelvin Armstrong, CEO, KAAR',
    quote:
      "MyHR is just like having my own HR support person within my business, because it's that accessible.",
    video: {
      image: customerKaarVideoImage,
      url: 'https://www.youtube.com/embed/G84pvPky_Yc',
      channel: 'youtube',
    },
    moreStoriesButton: true,
    className: 'kaar',
  },
  'carpet-court': {
    logo: customerCarpetCourtLogo,
    name: 'Mark Hogan, Chief Financial Officer',
    quote:
      "We've just gone through our first full performance review process with MyHR, and it's been seamless. Our employees are much more engaged.",
    video: {
      image: customerCarpetCourtVideoImage,
      url: 'https://www.youtube.com/embed/dayxsMbaL9U',
      channel: 'youtube',
    },
    className: '',
  },
  roosters: {
    logo: customerRoostersLogo,
    name: 'Samantha Barrett, Group People & Culture Manager',
    quote:
      'MyHR has become an extension of our HR team, providing customised support, and centralising all our HR documentation into one platform.',
    video: {
      image: customerRoostersVideoImage,
      url: 'https://www.youtube.com/embed/cAGcFvH_oCU',
      channel: 'youtube',
    },
    className: '',
  },
  hse: {
    logo: customerHseLogo,
    name: 'Amanda Tait, HR Coordinator, HSE',
    quote:
      'The hands-on HR support has been incredibly beneficial. They’ve helped us through a lot of sticky situations.',
    video: {
      image: customerHseVideoImage,
      url: 'https://www.youtube.com/embed/kYU7cOWOGyM',
      channel: 'youtube',
    },
    className: 'hse',
  },
  'save-the-kiwi': {
    logo: customerSaveTheKiwiLogo,
    name: 'Ross Halpin, Marketing & Sponsorship GM',
    quote:
      'MyHR provides peace of mind to the management team that we are doing the right thing.',
    video: {
      image:
        'https://images.ctfassets.net/euxb051oplm7/2Wt8XnDIotT4o5pj81c2y2/fc3f66040e827ca9b9de94767210c2f1/Screen_Shot_2022-09-05_at_10.41.28_AM-min.png',
      url: 'https://www.youtube.com/embed/WGqxTBxuetc',
      channel: 'youtube',
    },
    className: '',
  },
  'nz-warriors': {
    logo: customerNzWarriorsLogo,
    name: 'Cameron George, CEO One NZ Warriors',
    quote:
      "The support is far greater than what you'd get from any one individual. Brilliant service, brilliant people.",
    video: {
      image: customerNzWarriorsVideoImage,
      url: 'https://www.youtube.com/embed/PxQSGmSiUlA',
      channel: 'youtube',
    },
    className: '',
  },
  silverback: {
    logo: customerSilverbackLogo,
    name: 'Andrew Hopper, Founder, Silverback Treeworks',
    quote:
      "MyHR is just like having my own HR support person within my business because it's that accessible. The team at MyHR is helping me take my business to the next level with solid people practices that are easy to implement",
    video: {
      image: customerSilverbackVideoImage,
      url: null,
      channel: null,
    },
    className: '',
  },
}
