import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import Section from './Section';

import './TrustedBySection.scss';

const TrustedBySection = (props) => {
  const label = props.label || 'Trusted by';
  const labelCol = props.labelCol || { lg: 2 };
  const labelClassName = props.labelClassName || '';
  const itemCol = props.itemCol || { xs: 4, md: 2 };

  return (
    <Section className="TrustedBySection">
      <Container>
        <Row>
          <Col {...labelCol} className="d-flex align-items-center py-2 py-lg-0 my-auto">
            <div className={`TrustedBySection__label ${labelClassName}`}>{label}</div>
          </Col>
          <Col lg={12 - labelCol}>
            <Row>
              {props.items.map((item, index) => (
                <Col
                  {...itemCol}
                  className={`TrustedBySection__logo px-0 d-flex`}
                  key={index}
                >
                  {item}
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default TrustedBySection